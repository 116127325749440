import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Layout } from '../../components/layout';
import { Seo } from '../../components/seo';
import * as Styles from '../../styles/pages/_login.module.scss';
import * as CommonStyles from '../../styles/global/_common.module.scss';
import { BreadCrumb } from '../../components/breadcrumb';
import { BackGround } from '../../components/background';
import {Link, useIntl, navigate} from "gatsby-plugin-react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLine, faTwitter, faFacebook, faGoogle } from "@fortawesome/free-brands-svg-icons"
import {submitLine, submitTwitter, submitFacebook, submitGoogle} from "../../lib/SNSRedirects";
import {login} from "../../services/auth";
import LoadingElement from "../../components/LoadingElement";
import * as LoadingElementStyles from '../../styles/components/_loading_element.module.scss';

const LoginPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  const [isLoading, setIsLoading] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('')

  const onClickLogin = async () => {
    if (email === '') {
      setIsError(true)
      setErrorMessage(t('users.login.pleaseMailaddress'))
      return
    }
    setIsLoading(true)
    setIsError(false)
    try {
      const result = await login({ email, password })
      setIsLoading(false)
      if (result.data.status === 0) {
        await navigate('/')
      } else {
        await navigate('/users/nameregistration')
      }
    } catch (e) {
      setIsLoading(false)
      setIsError(true)
      setErrorMessage(t('users.login.errorLogin'))
    }
  }

  const onClickSNSLogin = async (provider) => {
    setIsLoading(true)
    if (provider === 'line') {
      await submitLine()
    } else if (provider === 'twitter') {
      await submitTwitter()
    } else if (provider === 'facebook') {
      await submitFacebook()
    } else if (provider === 'google') {
      await submitGoogle()
    } else {
      setIsLoading(false)
    }
  }


  return (
    <Layout location={location}>
      <div className={isLoading ? '' : LoadingElementStyles.hidden}>
        <LoadingElement />
      </div>
      <Seo title={t('users.login.seo.title')} description={t('users.login.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('users.login.title')}
      />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div id="users" className={Styles.users}>
              <h1>{t('users.login.accountLogin')}</h1>
              <section>
                <div className={Styles.flex_container}>
                  <div className={Styles.flex_item + ' ' + Styles.for_sp}>
                    <h2>{t('users.login.outerAccount')}</h2>
                    <hr/>
                    <ul>
                      <li>
                        <button className={Styles.btn_login + ' ' + Styles.icon_awesome} onClick={() => onClickSNSLogin('line')}>
                          <FontAwesomeIcon className={Styles.icon} icon={faLine} />
                          {t('users.login.loginLine')}
                        </button>
                      </li>
                      {/*<li>*/}
                      {/*  <button className={Styles.btn_login + ' ' + Styles.icon_awesome} onClick={() => onClickSNSLogin('twitter')}>*/}
                      {/*    <FontAwesomeIcon className={Styles.icon} icon={faTwitter} />*/}
                      {/*    {t('users.login.loginTwitter')}*/}
                      {/*  </button>*/}
                      {/*</li>*/}
                      <li>
                        <button className={Styles.btn_login + ' ' + Styles.icon_awesome} onClick={() => onClickSNSLogin('facebook')}>
                          <FontAwesomeIcon className={Styles.icon} icon={faFacebook} />
                          {t('users.login.loginFacebook')}
                        </button>
                      </li>
                      <li>
                        <button className={Styles.btn_login + ' ' + Styles.icon_awesome} onClick={() => onClickSNSLogin('google')}>
                          <FontAwesomeIcon className={Styles.icon} icon={faGoogle} />
                          {t('users.login.loginGoogle')}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className={Styles.flex_item + ' ' + Styles.for_sp}>
                    <h2>{t('users.login.loginMailaddress')}</h2>
                    <hr/>
                    <div>
                      <div>
                        <label htmlFor="user_email">{t('users.login.mailaddress')}</label>
                        <input
                          id="user_email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          autoFocus="autofocus"
                          type="email"
                        />
                      </div>
                      <div>
                        <label htmlFor="user_password">{t('users.login.password')}</label>
                        <input
                          id="user_password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          autoFocus="autofocus"
                          type="password"
                        />
                      </div>
                      <div className={`${Styles.error_message} ${(isError) && Styles.is_error}`}>{errorMessage}</div>
                      <div className={Styles.text_center}>
                        <button type="button" className={Styles.btn_primary} onClick={onClickLogin} disabled={isLoading}>
                          {t('users.login.title')}
                        </button>
                      </div>
                    </div>
                    <div>
                      <Link to="/users/password/forgot">{t('users.login.forgotPassword')}</Link><br/>
                      {/*<Link to="/users/confirmation/new">{t('users.login.message')}</Link>*/}
                    </div>
                  </div>
                </div>
                <hr/>
                <div className={Styles.mg_t3}>
                  <Link to="/users/signup" className={Styles.btn_common + ' ' + Styles.mg_b1}>{t('users.login.newAccount')}</Link><br/>
                  <Link to="/entry">{t('users.login.joinLimits')}</Link>
                </div>
              </section>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default LoginPage;
